var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "referenceTemplateHelp" }, [
    _c("i", {
      staticClass: "close-icon el-icon-close",
      on: { click: _vm.closeHelp },
    }),
    _vm._m(0),
    _vm._m(1),
    _vm._m(2),
    _vm._m(3),
    _vm._m(4),
    _c("hr"),
    _vm._m(5),
    _vm._m(6),
    _vm._m(7),
    _vm._m(8),
    _vm._m(9),
    _c("hr"),
    _vm._m(10),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("REFERENCE TEMPLATE HELP")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Reference template can contain "),
      _c("i", [_vm._v("tags")]),
      _vm._v(" for autofilled values:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("{prj}")]), _vm._v(": project code")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("{est}")]), _vm._v(": estimate code")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("b", [_vm._v("{seq}")]),
      _vm._v(": sequence of invoice in group, eg. 1/4"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" Date values are based on the invoice date:"),
      _c("br"),
      _c("b", [_vm._v("{week}")]),
      _vm._v(": week number of year | "),
      _c("b", [_vm._v("{mm}")]),
      _vm._v(": month number | "),
      _c("b", [_vm._v("{mon}")]),
      _vm._v(": month abbreviation | "),
      _c("b", [_vm._v("{month}")]),
      _vm._v(": month name | "),
      _c("b", [_vm._v("{year}")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        " Values can be adjusted with an offset, +/- the units being displayed:"
      ),
      _c("br"),
      _c("b", [_vm._v("{month|+1}")]),
      _vm._v(": add 1 month to the invoice date, then display the month name "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" Values can be adjusted by a different unit: "),
      _c("b", [_vm._v("{year|+1 month}")]),
      _vm._v(" - valid units are "),
      _c("b", [_vm._v("d/day")]),
      _vm._v(" | "),
      _c("b", [_vm._v("w/week")]),
      _vm._v(" | "),
      _c("b", [_vm._v("m/mm/mon/month")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        " Note that each tag is applied independently, so if more than one date value is used, they "
      ),
      _c("i", [_vm._v("all")]),
      _vm._v(" need to be adjusted the same:"),
      _c("br"),
      _vm._v(" eg. "),
      _c("b", [_vm._v("{week|+2 week}_{year|+2 week}")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" Tags are evaluated when the "),
      _c("i", [_vm._v("invoice")]),
      _vm._v(" is created, not when the payment plan is created."),
      _c("br"),
      _vm._v(
        " Unscheduled invoices will display tags based on the current date. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(" Example template:"),
      _c("br"),
      _c("b", [_vm._v("{prj}_MNT_W{week}_{year}_{seq}")]),
      _vm._v(" - CFS_MNT_W31_2023_1/4 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }