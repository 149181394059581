var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-container" },
    [
      _vm.is_credit
        ? _c("div", { staticClass: "credit-label" }, [_vm._v("Credit")])
        : _vm._e(),
      _c("el-form", { ref: "lineitem_form", attrs: { model: _vm.form } }, [
        _c(
          "div",
          { staticClass: "item-row" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 5, type: "flex", align: "middle" } },
              [
                _c("el-col", { attrs: { span: 10 } }, [
                  _c(
                    "div",
                    { staticClass: "row description" },
                    [
                      _c("el-input", {
                        staticClass: "left",
                        attrs: { name: "email", type: "text" },
                        on: { blur: _vm.updateLineItemTitle },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "span",
                      { staticClass: "el-input__inner input-value read-only" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.line_item.cost * _vm.sign)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "span",
                      { staticClass: "el-input__inner input-value read-only" },
                      [_vm._v(" " + _vm._s(_vm.line_item.discount ?? 0) + " ")]
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "span",
                      { staticClass: "el-input__inner input-value read-only" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.discounted_cost * _vm.sign)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "span",
                      { staticClass: "el-input__inner input-value read-only" },
                      [_vm._v(" " + _vm._s(_vm.invoiced_percent) + " ")]
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "span",
                      { staticClass: "el-input__inner input-value read-only" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.remaining * _vm.sign)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { span: 1 } }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: !_vm.is_invoiceable },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm.selected && !_vm.is_zero_amount
                        ? _c("el-input-number", {
                            ref: "input_percent",
                            staticClass: "input-value",
                            attrs: {
                              controls: false,
                              size: "small",
                              placeholder: "0",
                              min: 0,
                              max: 100 - _vm.invoiced_percent,
                              precision: 1,
                              step: 5,
                              disabled: !_vm.discounted_cost,
                            },
                            on: {
                              change: _vm.validatePercent,
                              focus: _vm.selectInput,
                            },
                            model: {
                              value: _vm.form.percent,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "percent", $$v)
                              },
                              expression: "form.percent",
                            },
                          })
                        : _c(
                            "span",
                            {
                              staticClass:
                                "el-input__inner input-value read-only",
                            },
                            [_vm._v(" - ")]
                          ),
                    ],
                    1
                  ),
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm.selected && _vm.is_credit
                        ? _c("span", { staticClass: "credit-prefix" }, [
                            _vm._v(" - "),
                          ])
                        : _vm._e(),
                      _vm.selected
                        ? _c(
                            "money",
                            _vm._b(
                              {
                                ref: "input_amount",
                                staticClass: "el-input__inner",
                                attrs: { disabled: _vm.is_zero_amount },
                                nativeOn: {
                                  blur: function ($event) {
                                    return _vm.validateAmount.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  focus: function ($event) {
                                    return _vm.selectInput.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "amount", $$v)
                                  },
                                  expression: "form.amount",
                                },
                              },
                              "money",
                              _vm.money,
                              false
                            )
                          )
                        : _c(
                            "span",
                            {
                              staticClass:
                                "el-input__inner input-value read-only",
                            },
                            [_vm._v(" - ")]
                          ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }