var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "paymentplan-totals" }, [
      _c(
        "div",
        { staticClass: "paymentplan-totals-header" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 5, type: "flex", align: "middle" } },
            [
              _c(
                "el-col",
                { staticClass: "description", attrs: { span: 19 } },
                [_vm._v(" Line item totals ")]
              ),
              _c("el-col", { staticClass: "center", attrs: { span: 2 } }, [
                _vm._v(" % allocated "),
              ]),
              _c("el-col", { staticClass: "center", attrs: { span: 3 } }, [
                _vm._v(" Amount remaining "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paymentplan-totals-line-items" },
        _vm._l(_vm.totals, function (line_item) {
          return _c("payment-plan-invoice-line-item", {
            key: line_item.id,
            attrs: { line_item: line_item, plan_total: true },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }