var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.estimate
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("el-button", { on: { click: _vm.goBackAction } }, [
                _vm._v("Back"),
              ]),
              _c("span", { staticClass: "ref" }, [
                _vm._v(" / "),
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.estimate.ref) + " - Payment plan"),
                ]),
              ]),
              !_vm.is_voided && !_vm.payment_plans.length
                ? _c(
                    "el-button",
                    {
                      staticClass: "voidButton",
                      attrs: { type: "danger", plain: "" },
                      on: { click: _vm.handleVoidPayment },
                    },
                    [_vm._v(" Void payment ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("hr"),
          _vm.is_voided
            ? _c(
                "div",
                { staticClass: "voided" },
                [
                  _c(
                    "el-alert",
                    {
                      attrs: {
                        "show-icon": "",
                        type: "error",
                        closable: false,
                      },
                    },
                    [_vm._v(" Payment has been voided for this estimate ")]
                  ),
                ],
                1
              )
            : [
                _vm.new_payment_plan
                  ? _c("paymentplan-create", {
                      attrs: {
                        estimate: _vm.estimate,
                        payment_plan: _vm.new_payment_plan,
                      },
                      on: {
                        reference: _vm.handleUpdateReference,
                        purchase_order: _vm.handleSetNewPurchaseOrder,
                        cancel: _vm.handleCancelCreate,
                        create: _vm.handleSavePaymentPlan,
                      },
                    })
                  : _vm._e(),
                _c("paymentplan-form", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.new_payment_plan && _vm.invoiceable,
                      expression: "!new_payment_plan && invoiceable",
                    },
                  ],
                  attrs: {
                    estimate: _vm.estimate,
                    line_items: _vm.billable_line_items,
                  },
                  on: { create: _vm.handleCreatePaymentPlan },
                }),
                !_vm.new_payment_plan
                  ? _c(
                      "div",
                      _vm._l(_vm.payment_plans, function (plan) {
                        return _c(
                          "div",
                          { key: plan.id, staticClass: "payment-plan" },
                          [
                            _c("div", { staticClass: "payment-plan-header" }, [
                              _c("div", { staticClass: "payment-plan-ref" }, [
                                _vm._v(_vm._s(plan.id)),
                              ]),
                              _c(
                                "div",
                                { staticClass: "payment-plan-controls" },
                                [
                                  !_vm.loading.payment_plan_invoices &&
                                  (_vm.isPurchaseOrderEditable(plan) ||
                                    plan.purchase_order)
                                    ? [
                                        _vm._v(" Purchase order: "),
                                        _c("el-input", {
                                          staticClass: "purchase-order",
                                          attrs: {
                                            size: "mini",
                                            disabled: !_vm.isPurchaseOrderEditable(
                                              plan
                                            ),
                                          },
                                          on: {
                                            change: () =>
                                              _vm.handleUpdatePurchaseOrder(
                                                plan
                                              ),
                                          },
                                          model: {
                                            value: plan.purchase_order,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                plan,
                                                "purchase_order",
                                                $$v
                                              )
                                            },
                                            expression: "plan.purchase_order",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "payment-plan-edit",
                                      attrs: {
                                        size: "mini",
                                        icon: !plan.contact_emails
                                          ? "el-icon-warning"
                                          : "",
                                        type: !plan.contact_emails
                                          ? "danger"
                                          : "default",
                                      },
                                      on: {
                                        click: () => {
                                          _vm.editPlanAction(plan)
                                        },
                                      },
                                    },
                                    [_vm._v(" Edit contacts ")]
                                  ),
                                ],
                                2
                              ),
                            ]),
                            _vm.loading.payment_plan_invoices
                              ? _c("loader")
                              : _c(
                                  "div",
                                  { staticClass: "payment-plan-invoices" },
                                  _vm._l(_vm.getInvoices(plan), function (
                                    invoice
                                  ) {
                                    return _c("payment-plan-invoice-block", {
                                      key: invoice.id,
                                      attrs: {
                                        invoice: invoice,
                                        project_ref: _vm.projectRef,
                                        estimate: _vm.estimate,
                                        invoices_count: _vm.getInvoices(plan)
                                          .length,
                                      },
                                      on: { click: _vm.handleClickInvoice },
                                    })
                                  }),
                                  1
                                ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }