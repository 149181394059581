var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-row",
        { staticClass: "paymentplan-header", attrs: { align: "left" } },
        [
          _c("el-col", { attrs: { span: 14 } }, [
            _c("span", [_vm._v("Reference template")]),
          ]),
          _c("el-col", { attrs: { span: 10 } }, [
            _c("span", [_vm._v("Purchase order")]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "paymentplan-form-fields",
          attrs: { type: "flex", align: "middle", gutter: 5 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 14 } },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-value left",
                  model: {
                    value: _vm.reference,
                    callback: function ($$v) {
                      _vm.reference = $$v
                    },
                    expression: "reference",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "prepend", icon: "el-icon-question" },
                    on: { click: _vm.toggleReferenceTemplateHelp },
                    slot: "prepend",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("el-input", {
                staticClass: "input-value left",
                on: { blur: _vm.handleUpdatePurchaseOrder },
                model: {
                  value: _vm.purchase_order,
                  callback: function ($$v) {
                    _vm.purchase_order = $$v
                  },
                  expression: "purchase_order",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showReferenceTemplateHelp
        ? _c("paymentplan-reference-help", {
            on: { close: _vm.toggleReferenceTemplateHelp },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "footer-note", staticStyle: { "margin-top": "10px" } },
        [
          _vm._v(
            " Changes to the master reference template will override any custom references below: "
          ),
        ]
      ),
      _vm._l(_vm.invoices, function (invoice) {
        return _c("paymentplan-invoice", {
          key: invoice.sequence,
          attrs: {
            estimate: _vm.estimate,
            payment_plan: _vm.payment_plan,
            invoice: invoice,
            draft: true,
          },
          on: { update: _vm.handleInvoiceUpdate },
        })
      }),
      _c("paymentplan-totals", {
        attrs: { payment_plan: _vm.payment_plan, totals: _vm.plan_totals },
      }),
      _c("paymentplan-emails", {
        attrs: { payment_plan: _vm.payment_plan },
        on: { change: _vm.handleUpdateContactEmails },
      }),
      _c("div", { staticClass: "footer-note" }, [
        _vm._v(
          " * Any dates in references for unscheduled invoices are displayed using today's date "
        ),
      ]),
      _c(
        "div",
        { staticClass: "paymentplan-form-footer" },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("Cancel"),
          ]),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.is_disabled },
              on: { click: _vm.handleCreate },
            },
            [_vm._v(" Create ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }