var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "paymentplan-form" }, [
      _c(
        "div",
        { staticClass: "paymentplan-list-header" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 5, type: "flex", align: "middle" } },
            [
              _c(
                "el-col",
                { staticClass: "description", attrs: { span: 10 } },
                [_vm._v(" Line item ")]
              ),
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", [_vm._v("Amount")]),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", [_vm._v("Discount")]),
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", [_vm._v("Cost")]),
              ]),
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", [_vm._v("Inv. %")]),
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", [_vm._v("Remaining")]),
              ]),
              _c("el-col", { attrs: { span: 1 } }),
              _c("el-col", { staticClass: "center", attrs: { span: 2 } }, [
                _c("span", [_vm._v("%")]),
              ]),
              _c("el-col", { staticClass: "center", attrs: { span: 3 } }, [
                _c("span", [_vm._v("Amount")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "paymentplan-form-content" },
        [
          _vm._l(_vm.sorted_line_items, function (line_item) {
            return _c("payment-plan-form-line", {
              key: line_item.id,
              attrs: { line_item: line_item },
              on: { update: _vm.updateLineItem },
            })
          }),
          _c("hr"),
          _c(
            "div",
            { staticClass: "paymentplan-form-header" },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { gutter: 5, type: "flex", align: "middle" },
                },
                [
                  _c("el-col", { attrs: { span: 14 } }, [
                    _vm._v(" Invoice reference template: "),
                  ]),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _vm._v(" Purchase order: "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "paymentplan-form-fields",
              attrs: { gutter: 5, type: "flex", align: "middle" },
            },
            [
              _c(
                "el-col",
                { staticClass: "end-field", attrs: { span: 14 } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "input-value left",
                      model: {
                        value: _vm.form.reference,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "reference", $$v)
                        },
                        expression: "form.reference",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "prepend", icon: "el-icon-question" },
                        on: { click: _vm.toggleReferenceTemplateHelp },
                        slot: "prepend",
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: { "font-size": "12px" },
                          attrs: { slot: "append" },
                          slot: "append",
                        },
                        [
                          _vm._v(" Preview: "),
                          _c("b", [_vm._v(_vm._s(_vm.reference_preview))]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "end-field", attrs: { span: 10 } },
                [
                  _c("el-input", {
                    staticClass: "input-value left",
                    model: {
                      value: _vm.form.purchase_order,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "purchase_order", $$v)
                      },
                      expression: "form.purchase_order",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "paymentplan-form-header" },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { gutter: 5, type: "flex", align: "middle" },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "left",
                      attrs: { span: _vm.scheduled_width },
                    },
                    [_vm._v(" Scheduled ")]
                  ),
                  _vm.is_scheduled
                    ? _c("el-col", { attrs: { span: 5 } }, [
                        _vm._v(" Occurs every "),
                      ])
                    : _vm._e(),
                  _vm.is_scheduled
                    ? _c("el-col", { attrs: { span: 3 } }, [
                        _vm._v(" Starting on "),
                      ])
                    : _vm._e(),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _vm._v(" Due date terms "),
                  ]),
                  _c("el-col", { staticClass: "center", attrs: { span: 2 } }, [
                    _vm._v(" # invoices "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "paymentplan-form-fields",
              attrs: { gutter: 5, type: "flex", align: "middle" },
            },
            [
              _c(
                "el-col",
                { staticClass: "left", attrs: { span: _vm.scheduled_width } },
                [
                  _c("el-switch", {
                    on: { change: _vm.toggleScheduled },
                    model: {
                      value: _vm.is_scheduled,
                      callback: function ($$v) {
                        _vm.is_scheduled = $$v
                      },
                      expression: "is_scheduled",
                    },
                  }),
                ],
                1
              ),
              _vm.is_scheduled
                ? _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c("el-input-number", {
                        staticClass: "input-value",
                        attrs: { controls: false },
                        model: {
                          value: _vm.form.occurrence_interval,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "occurrence_interval", $$v)
                          },
                          expression: "form.occurrence_interval",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.is_scheduled
                ? _c(
                    "el-col",
                    { staticClass: "end-field", attrs: { span: 3 } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.form.occurrence_period,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "occurrence_period", $$v)
                            },
                            expression: "form.occurrence_period",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              value: _vm.PAYMENT_PLAN_FREQUENCY.WEEK,
                              label: "week(s)",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: _vm.PAYMENT_PLAN_FREQUENCY.MONTH,
                              label: "month(s)",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.is_scheduled
                ? _c(
                    "el-col",
                    { staticClass: "end-field", attrs: { span: 3 } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "dd/MM/yyyy",
                          "picker-options": {
                            disabledDate: _vm.disabledStartDate,
                          },
                        },
                        model: {
                          value: _vm.form.start_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "start_date", $$v)
                          },
                          expression: "form.start_date",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 2 } },
                [
                  _c("el-input-number", {
                    staticClass: "input-value",
                    attrs: { controls: false, min: 1 },
                    on: { focus: _vm.selectInput },
                    model: {
                      value: _vm.form.due_value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "due_value", $$v)
                      },
                      expression: "form.due_value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "end-field", attrs: { span: 5 } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.due_terms,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "due_terms", $$v)
                        },
                        expression: "form.due_terms",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          value: _vm.PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_DATE,
                          label: "days after invoice date",
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          value:
                            _vm.PAYMENT_PLAN_DUE_TERMS.DAYS_AFTER_END_OF_MONTH,
                          label: "days after end of invoice month",
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          value: _vm.PAYMENT_PLAN_DUE_TERMS.DAY_OF_MONTH,
                          label: "of the current month",
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          value: _vm.PAYMENT_PLAN_DUE_TERMS.DAY_OF_NEXT_MONTH,
                          label: "of the following month",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "center", attrs: { span: 2 } },
                [
                  _c("el-input-number", {
                    staticClass: "input-value",
                    attrs: { controls: false, min: 1 },
                    on: { focus: _vm.selectInput },
                    model: {
                      value: _vm.form.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "quantity", $$v)
                      },
                      expression: "form.quantity",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showReferenceTemplateHelp
            ? _c("paymentplan-reference-help", {
                on: { close: _vm.toggleReferenceTemplateHelp },
              })
            : _vm._e(),
        ],
        2
      ),
    ]),
    _c(
      "div",
      { staticClass: "paymentplan-form-controls" },
      [
        _c("span"),
        _c(
          "el-button",
          {
            attrs: { disabled: _vm.none_selected },
            on: { click: _vm.handleCreatePaymentPlan },
          },
          [_vm._v(" Continue ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }